// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormImage_formImage__1j4y6 {\n  margin-right: 16px;\n  position: relative;\n  overflow: hidden;\n  border-radius: 13px;\n}\n.FormImage_formImage__delete__3XpQi {\n  background: rgba(0, 0, 0, 0.6);\n  color: #fff;\n  position: absolute;\n  top: 100%;\n  right: 0;\n  left: 0;\n  bottom: -100%;\n  padding-top: 32px;\n  transition: all 0.2s ease;\n}\n.FormImage_formImage__delete__3XpQi svg {\n  width: 23px;\n  height: 23px;\n}\n.FormImage_formImage__delete__3XpQi p {\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 26px;\n}\n.FormImage_formImage__delete_isShow__3elCu {\n  top: 0;\n  bottom: 0;\n  border-radius: 13px;\n  cursor: pointer;\n}\n\n.FormImage_formImage__1j4y6 img {\n  width: 100%;\n  object-fit: cover;\n  display: block;\n}", "",{"version":3,"sources":["webpack://src/components/UI/FormImage/FormImage.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;AACE;EACE,8BAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,aAAA;EACA,iBAAA;EACA,yBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEI;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;AAAN;AAGI;EACE,MAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AADN;;AAMA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;AAHF","sourcesContent":[".formImage {\n  margin-right: 16px;\n  position: relative;\n  overflow: hidden;\n  border-radius: 13px;\n\n  &__delete {\n    background: rgba(0, 0, 0, 0.6);\n    color: #fff;\n    position: absolute;\n    top: 100%;\n    right: 0;\n    left: 0;\n    bottom: -100%;\n    padding-top: 32px;\n    transition: all 0.2s ease;\n  \n    svg {\n      width: 23px;\n      height: 23px;      \n    }\n  \n    p {\n      font-weight: normal;\n      font-size: 16px;\n      line-height: 26px;\n    }\n  \n    &_isShow {\n      top: 0;\n      bottom: 0;\n      border-radius: 13px;\n      cursor: pointer;\n    }\n  }\n}\n\n.formImage img {\n  width: 100%;\n  object-fit: cover;\n  display: block;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formImage": "FormImage_formImage__1j4y6",
	"formImage__delete": "FormImage_formImage__delete__3XpQi",
	"formImage__delete_isShow": "FormImage_formImage__delete_isShow__3elCu"
};
export default ___CSS_LOADER_EXPORT___;
