import React, { useState } from 'react';
import axios from 'axios';
import Banner from '../../../components/UI/Banner';
import Activity from '../../../components/listPage/Activity';
import Adresses from '../../../components/UI/Adresses';
import NewsMap from '../../../components/UI/NewsMap';

import styles from './Cdcs.module.scss';
import BannerPng from '../../../assets/images/listPage/Banner/Banner.png';
import BannerWebp from '../../../assets/images/listPage/Banner/Banner.webp';

// import DATA from '../../../components/listPage/CodeClass/data';
import { domenURL } from '../../../domenRoute';
import authHeader from '../../../services/auth-header';

import CodeClass from '../../../components/listPage/CodeClass';

// eslint-disable-next-line camelcase
const cdc_url = `${domenURL}api/cdc`;

const CdcsAdmin = () => {
  const [, setLoading] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [cdc, setCdc] = React.useState([]);

  React.useEffect(() => {
    // eslint-disable-next-line camelcase
    axios.get(cdc_url, { headers: authHeader() }).then((response) => {
      setCdc(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Banner
        imagePng={BannerPng}
        imageWebp={BannerWebp}
        title="Список КОД-КЛАССОВ"
      />
      <section className={styles.codeClasses}>
        <Adresses adresses={['Главная', 'Код-классы']} className={styles.codeClasses__codeClassesMap} />
        <div className={styles.codeClasses__wrapper}>
          <div className={styles.codeClasses__items}>
            {/* eslint-disable-next-line no-shadow,react/no-this-in-sfc */}
            {Array.from(cdc, (cdc) => (
              <CodeClass
                key={cdc.cdcName}
                imageWebp={cdc.imageWebp}
                imagePng={cdc.imagePng}
                title={cdc.cdcName}
                color={cdc.color}
                personImageWebp={cdc.personImageWebp}
                personImagePng={cdc.personImagePng}
                personName={cdc.cdcAdminName}
              />
            ))}
          </div>
          <Activity />
        </div>
        <NewsMap title="Код классов на странице" numbers={[1, 10]} max="13" className={styles.codeClasses__codeClassesPag} />
      </section>
    </>
  );
};
export default React.memo(CdcsAdmin);
