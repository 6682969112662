// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddCard_addCard__3Vz7v {\n  width: 100%;\n}\n.AddCard_addCard__button__2Imgc {\n  width: 100%;\n  height: 50px;\n  background-color: #25DAC5;\n  border: none;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://src/components/AddCard/AddCard.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;AACJ","sourcesContent":[".addCard {\n  width: 100%;\n\n  &__button {\n    width: 100%;\n    height: 50px;\n    background-color: #25DAC5;\n    border: none;\n    color: #fff;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addCard": "AddCard_addCard__3Vz7v",
	"addCard__button": "AddCard_addCard__button__2Imgc"
};
export default ___CSS_LOADER_EXPORT___;
