// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".news_news__newsCard__3twjP {\n  margin-bottom: 34px;\n}\n.news_news__addCard__IAcHu {\n  margin-bottom: 33px;\n  width: 300px;\n}\n.news_news__newsMap__1v09f {\n  margin-top: 84px;\n}", "",{"version":3,"sources":["webpack://src/pages/code-classes/detail/news/news.module.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,YAAA;AADJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":[".news {\n  &__newsCard {\n    margin-bottom: 34px;\n  }\n  \n  &__addCard {\n    margin-bottom: 33px;\n    width: 300px;\n  }\n\n  &__newsMap {\n    margin-top: 84px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"news__newsCard": "news_news__newsCard__3twjP",
	"news__addCard": "news_news__addCard__IAcHu",
	"news__newsMap": "news_news__newsMap__1v09f"
};
export default ___CSS_LOADER_EXPORT___;
