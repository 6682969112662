// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormRegistration_formRegistration__3M0Kl {\n  width: 66%;\n  color: #fff;\n}\n.FormRegistration_formRegistration__title__2RDGC {\n  margin-bottom: 13px;\n}\n.FormRegistration_formRegistration__title__2RDGC h2 {\n  font-weight: bold;\n  font-size: 26px;\n  line-height: 39px;\n}\n.FormRegistration_formRegistration__title__2RDGC span {\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 27px;\n}\n.FormRegistration_formRegistration__submit__IN0ko {\n  width: 307px;\n  height: 57px;\n  background: #0575E6;\n  color: #fff;\n  border-radius: 30px;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 21px;\n  border: none;\n}", "",{"version":3,"sources":["webpack://src/components/FormRegistration/FormRegistration.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,WAAA;AACF;AACE;EACE,mBAAA;AACJ;AACI;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;AACN;AAEI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAAN;AAIE;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AAFJ","sourcesContent":[".formRegistration {\n  width: 66%;\n  color: #fff;\n\n  &__title {\n    margin-bottom: 13px;\n\n    h2 {\n      font-weight: bold;\n      font-size: 26px;\n      line-height: 39px;\n    }\n\n    span {\n      font-weight: 400;\n      font-size: 18px;\n      line-height: 27px;      \n    }\n  }\n\n  &__submit {\n    width: 307px;\n    height: 57px;\n    background: #0575E6;\n    color: #fff;\n    border-radius: 30px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 21px;\n    border: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRegistration": "FormRegistration_formRegistration__3M0Kl",
	"formRegistration__title": "FormRegistration_formRegistration__title__2RDGC",
	"formRegistration__submit": "FormRegistration_formRegistration__submit__IN0ko"
};
export default ___CSS_LOADER_EXPORT___;
