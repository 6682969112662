import React, { useState, memo } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styles from './FormChangePassword.module.scss';
import Input from '../UI/Input';
import Button from '../UI/Button';
import authHeader from '../../services/auth-header';
import { domenURL } from '../../domenRoute';
import store from '../../store';

const FormChangePassword = () => {
  const [email, setEmail] = useState('');
  const [password, setNewPass] = useState('');
  const [repNewPass, setRepNewPass] = useState('');
  const [next, setNext] = useState(0);

  const { message } = useSelector((state) => state.message);

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState([]);
  store.subscribe(() => setUser(store.getState()));

  const onChangeEmail = (e) => {
    // eslint-disable-next-line no-shadow
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    // eslint-disable-next-line no-shadow
    const password = e.target.value;
    setNewPass(password);
  };

  const onChangeRepNewPass = (e) => {
    // eslint-disable-next-line no-shadow
    const repNewPass = e.target.value;
    setRepNewPass(repNewPass);
  };

  const url = `${domenURL}api/auth/changeUser`;
  const data = {
    email, password,
  };

  const postData = () => axios.post(url, data, { headers: authHeader() });

  return (
    <div className={cn(styles.formChangePassword)}>
      <div className={styles.formChangePassword__title}>Смена пароля</div>
      <div className={styles.formChangePassword__contentWrapper}>
        <div className={styles.formChangePassword__content} style={{ transform: `translateX(calc(${next} * -100%))` }}>
          <div className={styles.formChangePassword__item}>
            <div className={styles.formChangePassword__description}>
              Для смены пароля введите ваш Email
            </div>
            <Input
              type="email"
              name="email"
              icon="inputEmail"
              placeholder="Email"
              value={email}
              onChange={onChangeEmail}
              text={email}
              error="Заполните поле"
            />
            <Button
              className={cn(styles.formChangePassword__submit, !/(.+)@(.+)/.test(email) && styles.formChangePassword__submit_disabled)}
              onClick={() => setNext(1)}
            >
              Далее
            </Button>
          </div>
          <div className={styles.formChangePassword__item}>
            <div className={styles.formChangePassword__description}>
              Введите новый пароль
            </div>
            <Input
              type="password"
              name="password"
              icon="inputPassword"
              placeholder="Новый пароль"
              value={password}
              onChange={onChangePassword}
              text={password}
              error="Заполните поле"
            />
            <Input
              type="password"
              name="password"
              icon="inputPassword"
              placeholder="Повторите новый пароль"
              value={repNewPass}
              onChange={onChangeRepNewPass}
              text={repNewPass}
              error="Заполните поле"
            />
            {/* eslint-disable-next-line no-sequences */}
            <Button onClick={(e) => (e.preventDefault(), postData())} className={cn(styles.formChangePassword__submit, next !== 0 && !/(.+)@(.+)/.test(email) && styles.formChangePassword__submit_disabled)}>
              Изменить пароль
            </Button>
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FormChangePassword);
