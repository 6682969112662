// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsCdc_codeClassesDetail__3cvCR {\n  display: block;\n}\n.NewsCdc_codeClassesDetail__adress__33Sag {\n  margin: 42px 0 28px 0;\n}\n.NewsCdc_codeClassesDetail__toggle__3BfqT {\n  margin: 34px 0 31px 0;\n}\n.NewsCdc_codeClassesDetail__newsCard__2n2qC {\n  margin-bottom: 34px;\n}\n.NewsCdc_codeClassesDetail__container__3tDFk {\n  max-width: 1170px;\n  margin: 0 auto;\n}\n.NewsCdc_codeClassesDetail__banners__3o4bv {\n  max-width: 770px;\n}\n.NewsCdc_codeClassesDetail__wrapper__1WCh5 {\n  display: flex;\n  justify-content: space-between;\n}\n@media (max-width: 1440px) {\n  .NewsCdc_codeClassesDetail__3cvCR .NewsCdc_codeClassesDetail__container__3tDFk {\n    margin-left: 120px;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/code-classes/detail/NewsCdc.module.scss"],"names":[],"mappings":"AAAA;EAGE,cAAA;AADF;AAGE;EACE,qBAAA;AADJ;AAIE;EACE,qBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAME;EACE,iBAAA;EACA,cAAA;AAJJ;AAOE;EACE,gBAAA;AALJ;AAQE;EACE,aAAA;EACA,8BAAA;AANJ;AAQE;EACE;IACE,kBAAA;EANJ;AACF","sourcesContent":[".codeClassesDetail {\n  $self: &;\n\n  display: block;\n\n  &__adress {\n    margin: 42px 0 28px 0;\n  }\n\n  &__toggle {\n    margin: 34px 0 31px 0;\n  }\n\n  &__newsCard {\n    margin-bottom: 34px;\n  }\n\n  &__container {\n    max-width: 1170px;\n    margin: 0 auto;\n  }\n\n  &__banners {\n    max-width: 770px;\n  }\n\n  &__wrapper {\n    display: flex;\n    justify-content: space-between;\n  }\n  @media (max-width: 1440px) {\n    #{$self}__container {\n      margin-left: 120px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeClassesDetail": "NewsCdc_codeClassesDetail__3cvCR",
	"codeClassesDetail__adress": "NewsCdc_codeClassesDetail__adress__33Sag",
	"codeClassesDetail__toggle": "NewsCdc_codeClassesDetail__toggle__3BfqT",
	"codeClassesDetail__newsCard": "NewsCdc_codeClassesDetail__newsCard__2n2qC",
	"codeClassesDetail__container": "NewsCdc_codeClassesDetail__container__3tDFk",
	"codeClassesDetail__banners": "NewsCdc_codeClassesDetail__banners__3o4bv",
	"codeClassesDetail__wrapper": "NewsCdc_codeClassesDetail__wrapper__1WCh5"
};
export default ___CSS_LOADER_EXPORT___;
