// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toggle_toggle__xV_Sg {\n  width: 100%;\n}\n.Toggle_toggle__menuItems__7KURP {\n  display: flex;\n  list-style: none;\n  border-bottom: 1px solid #D3D3D3;\n}\n.Toggle_toggle__menuItem__2-Rvq a {\n  padding: 12px 23px;\n  text-decoration: none;\n  font-weight: 500;\n  font-size: 18px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  line-height: 28px;\n  color: #1E0E62;\n}\n.Toggle_toggle__menuItem_small__20Ygp a {\n  padding: 12px;\n}\n.Toggle_toggle__active__2yQ_O {\n  background-color: #E0E0E0;\n  border-radius: 7px 7px 0 0;\n}", "",{"version":3,"sources":["webpack://src/components/Toggle/Toggle.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,aAAA;EACA,gBAAA;EACA,gCAAA;AACJ;AAGI;EACE,kBAAA;EACA,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AADN;AAII;EACE,aAAA;AAFN;AAME;EACE,yBAAA;EACA,0BAAA;AAJJ","sourcesContent":[".toggle {\n  width: 100%;\n\n  &__menuItems {\n    display: flex;\n    list-style: none;\n    border-bottom: 1px solid #D3D3D3;\n  }\n\n  &__menuItem {\n    a {\n      padding: 12px 23px;\n      text-decoration: none;\n      font-weight: 500;\n      font-size: 18px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      line-height: 28px;\n      color: #1E0E62;\n    }\n\n    &_small a {\n      padding: 12px;\n    }\n  }\n\n  &__active {\n    background-color: #E0E0E0;\n    border-radius: 7px 7px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": "Toggle_toggle__xV_Sg",
	"toggle__menuItems": "Toggle_toggle__menuItems__7KURP",
	"toggle__menuItem": "Toggle_toggle__menuItem__2-Rvq",
	"toggle__menuItem_small": "Toggle_toggle__menuItem_small__20Ygp",
	"toggle__active": "Toggle_toggle__active__2yQ_O"
};
export default ___CSS_LOADER_EXPORT___;
