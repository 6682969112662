// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Adresses_adresses__8djSm {\n  display: flex;\n  align-items: center;\n}\n.Adresses_adresses__adress__3Pt3b {\n  display: block;\n  position: relative;\n  margin-right: 17px;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 16px;\n  color: rgba(0, 0, 0, 0.54);\n}\n.Adresses_adresses__adress__3Pt3b:last-of-type {\n  margin-right: 0;\n  color: rgba(0, 0, 0, 0.87);\n}\n.Adresses_adresses__adress__3Pt3b:last-of-type:after {\n  display: none;\n}\n.Adresses_adresses__adress__3Pt3b:after {\n  content: \"\";\n  display: inline-block;\n  width: 7px;\n  height: 7px;\n  border-bottom: 2px solid rgba(0, 0, 0, 0.54);\n  border-right: 2px solid rgba(0, 0, 0, 0.54);\n  margin-left: 12px;\n  transform: rotate(-45deg);\n}", "",{"version":3,"sources":["webpack://src/components/UI/Adresses/Adresses.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;AACJ;AACI;EACE,eAAA;EACA,0BAAA;AACN;AACM;EACE,aAAA;AACR;AAGI;EACE,WAAA;EACA,qBAAA;EACA,UAAA;EACA,WAAA;EACA,4CAAA;EACA,2CAAA;EACA,iBAAA;EACA,yBAAA;AADN","sourcesContent":[".adresses {\n  display: flex;\n  align-items: center;\n\n  &__adress {\n    display: block;\n    position: relative;\n    margin-right: 17px;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 16px;\n    color: rgba(0, 0, 0, 0.54);\n\n    &:last-of-type {\n      margin-right: 0;\n      color: rgba(0, 0, 0, 0.87);\n\n      &:after {\n        display: none;\n      }\n    }\n\n    &:after {\n      content: \"\";\n      display: inline-block;\n      width: 7px;\n      height: 7px;\n      border-bottom: 2px solid rgba(0, 0, 0, 0.54);\n      border-right: 2px solid rgba(0, 0, 0, 0.54);\n      margin-left: 12px;\n      transform: rotate(-45deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adresses": "Adresses_adresses__8djSm",
	"adresses__adress": "Adresses_adresses__adress__3Pt3b"
};
export default ___CSS_LOADER_EXPORT___;
