// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon_icon__pAdqh svg {\n  max-width: 100%;\n  max-height: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Icon/Icon.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".icon {\n  svg {\n    max-width: 100%;\n    max-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "Icon_icon__pAdqh"
};
export default ___CSS_LOADER_EXPORT___;
