// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_button__32FwK {\n  box-sizing: border-box;\n  border-radius: 100px;\n  padding: 12px 23px;\n  border: none;\n  outline: none;\n  text-decoration: none;\n  font-size: 20px;\n}\n.Button_button__link__2bH3w {\n  display: block;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,oBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;EACA,eAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;AACJ","sourcesContent":[".button {\n  box-sizing: border-box;\n  border-radius: 100px;\n  padding: 12px 23px;\n  border: none;\n  outline: none;\n  text-decoration: none;\n  font-size: 20px;\n\n  &__link {\n    display: block;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Button_button__32FwK",
	"button__link": "Button_button__link__2bH3w"
};
export default ___CSS_LOADER_EXPORT___;
