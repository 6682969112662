// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".events_events__cardEvent__TZ_2C {\n  margin-bottom: 43px;\n}\n.events_events__addCard__1C00y {\n  margin-bottom: 33px;\n}\n.events_events__newsMap__T2qMV {\n  margin-top: 37px;\n}", "",{"version":3,"sources":["webpack://src/pages/code-classes/detail/events/events.module.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":[".events {\n  &__cardEvent {\n    margin-bottom: 43px;\n  }\n  \n  &__addCard {\n    margin-bottom: 33px;\n  }\n\n  &__newsMap {\n    margin-top: 37px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"events__cardEvent": "events_events__cardEvent__TZ_2C",
	"events__addCard": "events_events__addCard__1C00y",
	"events__newsMap": "events_events__newsMap__T2qMV"
};
export default ___CSS_LOADER_EXPORT___;
