// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search_search__1Gf21 {\n  width: 100%;\n  display: flex;\n}\n.Search_search__input__2HLhI {\n  width: calc(100% - 95px);\n  height: 50px;\n  padding: 12px 24px;\n  margin-right: 12px;\n  background-color: transparent;\n  border: 2px solid #ebeaed;\n  border-radius: 100px;\n  flex: 1 1;\n}\n.Search_search__circle__RuMb3 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  background: #25dac5;\n  cursor: pointer;\n}\n.Search_search__circle__RuMb3 svg {\n  width: 25px;\n  height: 25px;\n  color: white;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Search/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AACE;EACE,wBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,6BAAA;EACA,yBAAA;EACA,oBAAA;EACA,SAAA;AACJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,YAAA;AAAN","sourcesContent":[".search {\n  width: 100%;\n  display: flex;\n\n  &__input {\n    width: calc(100% - 95px);\n    height: 50px;\n    padding: 12px 24px;\n    margin-right: 12px;\n    background-color: transparent;\n    border: 2px solid #ebeaed;\n    border-radius: 100px;\n    flex: 1;\n  }\n\n  &__circle {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    background: #25dac5;\n    cursor: pointer;\n\n    svg {\n      width: 25px;\n      height: 25px;\n      color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "Search_search__1Gf21",
	"search__input": "Search_search__input__2HLhI",
	"search__circle": "Search_search__circle__RuMb3"
};
export default ___CSS_LOADER_EXPORT___;
