import React, { useState } from 'react';
import axios from 'axios';
import cn from 'classnames';
import Banner from '../../../components/UI/Banner';
import Activity from '../../../components/listPage/Activity';
import Adresses from '../../../components/UI/Adresses';
import NewsMap from '../../../components/UI/NewsMap';

import styles from './Users.module.scss';
import BannerPng from '../../../assets/images/listPage/Banner/Banner.png';
import BannerWebp from '../../../assets/images/listPage/Banner/Banner.webp';

// import DATA from '../../../components/listPage/CodeClass/data';
import { domenURL } from '../../../domenRoute';
import authHeader from '../../../services/auth-header';

import Button from '../../../components/UI/Button';
import Input from '../../../components/UI/Input';

// eslint-disable-next-line camelcase
const cdc_url = `${domenURL}api/users`;
// eslint-disable-next-line camelcase
const user_url = `${domenURL}api/auth/changeUser`;

const UsersAdmin = () => {
  const [isLoading, setLoading] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [users, setCdc] = React.useState();

  React.useEffect(() => {
    // eslint-disable-next-line camelcase
    axios.get(cdc_url, { headers: authHeader() }).then((response) => {
      setCdc(response.data);
      setLoading(false);
    });
  }, []);

  const [roles, setNewRoles] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState('');

  const onChangeRoles = (e) => {
    // eslint-disable-next-line no-shadow
    const roles = e.target.value;
    setNewRoles(roles);
  };

  const data = {
    email, roles,
  };
  const postData = () => axios.post(user_url, data, { headers: authHeader() });

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <>
      <Banner
        imagePng={BannerPng}
        imageWebp={BannerWebp}
        title="Список КОД-КЛАССОВ"
      />
      <section className={styles.codeClasses}>
        <Adresses adresses={['Главная', 'Код-классы']} className={styles.codeClasses__codeClassesMap} />
        <div className={styles.codeClasses__wrapper}>
          <div className={styles.codeClasses__items}>
            {/* eslint-disable-next-line no-shadow */}
            {Array.from(users, (item) => (
              <div className={styles.codeClass}>
                <div className={styles.codeClass__description}>
                  <div className={styles.codeClass__title}>
                    <h3>{item.username}</h3>
                  </div>
                  <div className={styles.codeClass__title}>
                    <h3>{item.id}</h3>
                  </div>
                  <div className={styles.codeClass__info}>
                    <div className={styles.codeClass__buttons}>
                      <Input
                        type="role"
                        name="role"
                        icon="inputRole"
                        placeholder=""
                        value={roles}
                        onChange={onChangeRoles}
                        text={roles}
                        error="Заполните поле"
                      />
                      {/* eslint-disable-next-line max-len */}
                      {/* eslint-disable-next-line no-sequences,max-len,react/no-this-in-sfc,no-return-assign */}
                      <Button onClick={(e) => (e.preventDefault(), this.email = item.id, postData())} className={cn(styles.formChangePassword__submit)}>
                        Изменить роли
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Activity />
        </div>
        <NewsMap title="Код классов на странице" numbers={[1, 10]} max="13" className={styles.codeClasses__codeClassesPag} />
      </section>
    </>
  );
};
export default React.memo(UsersAdmin);

