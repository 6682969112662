// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_events__2NSZ7 {\n  width: 100%;\n  background-color: #f3f7fa;\n}\n.styles_events__content__DBPoS {\n  display: flex;\n  flex-direction: column;\n  max-width: 1170px;\n  margin: 0 auto;\n}\n.styles_events__card__2Bazg + .styles_events__card__2Bazg {\n  margin-top: 32px;\n}\n.styles_events__map__rdWGJ {\n  margin: 40px 0;\n}\n.styles_events__search__ZM423 {\n  margin-bottom: 45px;\n}", "",{"version":3,"sources":["webpack://src/pages/events/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAGI;EACE,gBAAA;AADN;AAKE;EACE,cAAA;AAHJ;AAME;EACE,mBAAA;AAJJ","sourcesContent":[".events {\n  width: 100%;\n  background-color: #f3f7fa;\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    max-width: 1170px;\n    margin: 0 auto;\n  }\n\n  &__card {\n    & + & {\n      margin-top: 32px;\n    }\n  }\n\n  &__map {\n    margin: 40px 0;\n  }\n\n  &__search {\n    margin-bottom: 45px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"events": "styles_events__2NSZ7",
	"events__content": "styles_events__content__DBPoS",
	"events__card": "styles_events__card__2Bazg",
	"events__map": "styles_events__map__rdWGJ",
	"events__search": "styles_events__search__ZM423"
};
export default ___CSS_LOADER_EXPORT___;
