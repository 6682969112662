// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SocialIcon_icon__OPvHI {\n  width: 33.6px;\n  height: 33.6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 6px;\n}\n.SocialIcon_icon_circle__-ksWl {\n  background-color: #fff;\n  border-radius: 50%;\n  box-shadow: 0 0 3px 0.5px #e4eaef;\n}\n.SocialIcon_icon__OPvHI svg {\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/UI/SocialIcon/SocialIcon.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,sBAAA;EACA,kBAAA;EACA,iCAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".icon {\n  width: 33.6px;\n  height: 33.6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 6px;\n\n  &_circle {\n    background-color: #fff;\n    border-radius: 50%;\n    box-shadow: 0 0 3px 0.5px #e4eaef;\n  }\n\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "SocialIcon_icon__OPvHI",
	"icon_circle": "SocialIcon_icon_circle__-ksWl"
};
export default ___CSS_LOADER_EXPORT___;
