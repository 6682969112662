// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SmallModal_smallModal__2rp88 {\n  position: fixed;\n  top: 10%;\n  left: 22.5%;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  max-width: 1038px;\n  max-height: 705px;\n  background: rgba(47, 24, 147, 0.65);\n  -webkit-backdrop-filter: blur(76px);\n          backdrop-filter: blur(76px);\n  border-radius: 24px;\n  padding: 255px 363px 280px 364px;\n  z-index: 10;\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.3s ease;\n}\n.SmallModal_smallModal_open__1UADC {\n  opacity: 1;\n  visibility: visible;\n}\n@media (max-width: 1440px) {\n  .SmallModal_smallModal__2rp88 {\n    padding: 155px 233px 140px 233px;\n    max-width: 769px;\n    max-height: 464px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/UI/SmallModal/SmallModal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,mCAAA;EACA,mCAAA;UAAA,2BAAA;EACA,mBAAA;EACA,gCAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,yBAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;AACJ;AAEE;EAvBF;IAwBI,gCAAA;IACA,gBAAA;IACA,iBAAA;EACF;AACF","sourcesContent":[".smallModal {\n  position: fixed;\n  top: 10%;\n  left: 22.5%;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  max-width: 1038px;\n  max-height: 705px;\n  background: rgba(47, 24, 147, 0.65);\n  backdrop-filter: blur(76px);\n  border-radius: 24px;\n  padding: 255px 363px 280px 364px;\n  z-index: 10;\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.3s ease;\n\n  &_open {\n    opacity: 1;\n    visibility: visible;\n  }\n\n  @media (max-width: 1440px) {\n    padding: 155px 233px 140px 233px;\n    max-width: 769px;\n    max-height: 464px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallModal": "SmallModal_smallModal__2rp88",
	"smallModal_open": "SmallModal_smallModal_open__1UADC"
};
export default ___CSS_LOADER_EXPORT___;
